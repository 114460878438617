<template>
  <div>
    <div class="mb-3 row">
      <div class="col-6">
      <label for="quantity" class="form-label required">Quantity:</label>
      <input type="text" id="quantity" v-model="goldData.quantity" required class="form-control" placeholder="Enter the quantity (e.g., 100, 500)">
      </div>

      <div class="col-6">
      <label for="quantity" class="form-label required">Quantity Type:</label>
      <input type="text" id="quantity" v-model="goldData.quantity_type" required class="form-control" placeholder="Enter the quantity type (e.g., grams, ounces, carats)">
      </div>
    </div>

    <div class="mb-3">
      <label for="purity" class="form-label required">Purity:</label>
      <input type="text" id="purity" class="form-control" required v-model="goldData.gold_purity" placeholder="Enter the gold purity (e.g., 24 carats)">
    </div>

    <div class="mb-3">
      <label for="format" class="form-label required">Format:</label>
      <select id="format" class="form-select" required v-model="goldData.gold_format">
        <option selected disabled>Select a format</option>
        <option value="bars">Bars</option>
        <option value="coins">Coins</option>
        <option value="jewelry">Jewelry</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="manufacturer" class="form-label required">Manufacturer's Mark:</label>
      <input type="text" id="manufacturer" v-model="goldData.gold_brand" required class="form-control" placeholder="Enter the manufacturer's mark (e.g., mint or refiner)">
    </div>

    <div class="mb-3">
      <label for="serialNumber" class="form-label required">Serial Number:</label>
      <input type="text" id="serialNumber" v-model="goldData.gold_serial_number" required class="form-control" placeholder="Enter the unique serial number">
    </div>

    <div class="mb-3">
      <label for="manufactureDate" class="form-label required">Manufacture Date:</label>
      <input type="date" id="manufactureDate" required v-model="goldData.gold_create_date" class="form-control">
    </div>

    <div class="mb-3">
      <label for="storage" class="form-label required">Storage:</label>
      <input type="text" id="storage" class="form-control" required v-model="goldData.storage" placeholder="Enter storage location and conditions">
    </div>

    <div class="mb-3">
      <label for="certifications" class="form-label">Certifications:</label>
      <input type="text" id="certifications" class="form-control" v-model="goldData.certifications" placeholder="Enter relevant certifications or standards (e.g., LBMA Good Delivery List, Responsible Gold Guidance)">
    </div>

    <div class="mb-3">
      <label for="origin" class="form-label required">Origin:</label>
      <input type="text" id="origin" class="form-control" required v-model="goldData.origin" placeholder="Enter the country or region of origin">
    </div>

    <div class="mb-3 row">
      <div class="col-6">
        <label for="image1" class="form-label">Image 1:</label>
        <input type="file" id="image1" class="form-control" @change="onFileChange('img_1', $event)">
      </div>

      <div class="col-6">
        <label for="image2" class="form-label">Image 2:</label>
        <input type="file" id="image2" class="form-control" @change="onFileChange('img_2', $event)">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoldForm",
  data() {
    return {
      goldData: {
        // coffee fields
      },
      image1: null,
      image2: null,
    };
  },
  methods: {
    emitUpdate() {
      this.$emit("update-asset-data", this.goldData);
    },
    onFileChange(image, event) {
      const files = event.target.files;
      if (files.length > 0) {
        this[image] = files[0];
      } else {
        this[image] = null;
      }
    },
  },
  watch: {
    goldData: {
      deep: true,
      handler() {
        this.emitUpdate();
      },
    },
  },
};
</script>

<style scoped>

</style>