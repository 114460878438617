<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h2 class="mb-4">Create Organization Token</h2>
      <SoftAlert
        v-if="messages.success !== undefined"
        :icon="messages.success ? 'fas fa-thumbs-up' : 'fas fa-times-circle'"
        :message="messages.text"
        :color="messages.success ? 'success' : 'danger'"
      />
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="name" class="form-label required">Company Name:</label>
          <input type="text" id="companyName" class="form-control" required v-model="formData.name" placeholder="Enter company name">
        </div>

        <div class="mb-3">
          <label for="business_area" class="form-label required">Business Area:</label>
          <select id="business_area" class="form-select" required v-model="formData.business_area">
            <option disabled value="">Select business area</option>
            <option value="technology">Technology</option>
            <option value="finance">Finance</option>
            <option value="healthcare">Healthcare</option>
            <option value="education">Education</option>
            <option value="manufacturing">Manufacturing</option>
            <option value="retail">Retail</option>
            <option value="realEstate">Real Estate</option>
            <option value="construction">Construction</option>
            <option value="transportation">Transportation</option>
            <option value="logistics">Logistics</option>
            <option value="hospitality">Hospitality</option>
            <option value="foodService">Food Service</option>
            <option value="agriculture">Agriculture</option>
            <option value="automotive">Automotive</option>
            <option value="energy">Energy</option>
            <option value="utilities">Utilities</option>
            <option value="pharmaceuticals">Pharmaceuticals</option>
            <option value="chemicals">Chemicals</option>
            <option value="consumerGoods">Consumer Goods</option>
            <option value="media">Media</option>
            <option value="entertainment">Entertainment</option>
            <option value="publishing">Publishing</option>
            <option value="telecommunications">Telecommunications</option>
            <option value="biotechnology">Biotechnology</option>
            <option value="mining">Mining</option>
            <option value="metals">Metals</option>
            <option value="fashion">Fashion</option>
            <option value="textiles">Textiles</option>
            <option value="furniture">Furniture</option>
            <option value="electronics">Electronics</option>
            <option value="computerHardware">Computer Hardware</option>
            <option value="computerSoftware">Computer Software</option>
            <option value="internet">Internet</option>
            <option value="ITServices">IT Services</option>
            <option value="consulting">Consulting</option>
            <option value="advertising">Advertising</option>
            <option value="marketing">Marketing</option>
            <option value="legalServices">Legal Services</option>
            <option value="insurance">Insurance</option>
            <option value="banking">Banking</option>
            <option value="investmentBanking">Investment Banking</option>
            <option value="assetManagement">Asset Management</option>
            <option value="environmentalServices">Environmental Services</option>
            <option value="aerospace">Aerospace</option>
            <option value="defense">Defense</option>
            <option value="marine">Marine</option>
            <option value="paperAndPulp">Paper and Pulp</option>
            <option value="printing">Printing</option>
            <option value="recycling">Recycling</option>
            <option value="sport">Sport</option>
            <option value="toys">Toys</option>
            <option value="crafts">Crafts</option>
            <option value="GovernmentServices">Government services</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="company_size" class="form-label required">Company Size:</label>
          <select id="company_size" class="form-select" required v-model="formData.company_size">
            <option disabled value="">Select company size</option>
            <option value="1-50">1-50</option>
            <option value="100-1000">100-1000</option>
            <option value="10000-50000">10,000 - 50,000</option>
            <option value="50000+">50,000+</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="owner" class="form-label required">Owner:</label>
          <input type="text" id="owner" class="form-control" required v-model="formData.owner" placeholder="Owner's name">
        </div>

        <div class="mb-3">
          <label for="country" class="form-label required">Country:</label>
          <select id="country" class="form-select" required v-model="formData.country">
            <option disabled value="">Select country</option>
            <option value="afghanistan">Afghanistan</option>
            <option value="albania">Albania</option>
            <option value="algeria">Algeria</option>
            <option value="andorra">Andorra</option>
            <option value="angola">Angola</option>
            <option value="antigua-and-barbuda">Antigua and Barbuda</option>
            <option value="argentina">Argentina</option>
            <option value="armenia">Armenia</option>
            <option value="australia">Australia</option>
            <option value="austria">Austria</option>
            <option value="azerbaijan">Azerbaijan</option>
            <option value="bahamas">Bahamas</option>
            <option value="bahrain">Bahrain</option>
            <option value="bangladesh">Bangladesh</option>
            <option value="barbados">Barbados</option>
            <option value="belarus">Belarus</option>
            <option value="belgium">Belgium</option>
            <option value="belize">Belize</option>
            <option value="benin">Benin</option>
            <option value="bhutan">Bhutan</option>
            <option value="bolivia">Bolivia</option>
            <option value="bosnia-and-herzegovina">Bosnia and Herzegovina</option>
            <option value="botswana">Botswana</option>
            <option value="brazil">Brazil</option>
            <option value="brunei">Brunei</option>
            <option value="bulgaria">Bulgaria</option>
            <option value="burkina-faso">Burkina Faso</option>
            <option value="burundi">Burundi</option>
            <option value="cabo-verde">Cabo Verde</option>
            <option value="cambodia">Cambodia</option>
            <option value="cameroon">Cameroon</option>
            <option value="canada">Canada</option>
            <option value="central-african-republic">Central African Republic</option>
            <option value="chad">Chad</option>
            <option value="chile">Chile</option>
            <option value="china">China</option>
            <option value="colombia">Colombia</option>
            <option value="comoros">Comoros</option>
            <option value="congo-republic">Congo (Republic of the)</option>
            <option value="costa-rica">Costa Rica</option>
            <option value="cote-divoire">Côte d'Ivoire</option>
            <option value="croatia">Croatia</option>
            <option value="cuba">Cuba</option>
            <option value="cyprus">Cyprus</option>
            <option value="czech-republic">Czech Republic</option>
            <option value="denmark">Denmark</option>
            <option value="djibouti">Djibouti</option>
            <option value="dominica">Dominica</option>
            <option value="dominican-republic">Dominican Republic</option>
            <option value="ecuador">Ecuador</option>
            <option value="egypt">Egypt</option>
            <option value="el-salvador">El Salvador</option>
            <option value="equatorial-guinea">Equatorial Guinea</option>
            <option value="eritrea">Eritrea</option>
            <option value="estonia">Estonia</option>
            <option value="eswatini">Eswatini</option>
            <option value="ethiopia">Ethiopia</option>
            <option value="fiji">Fiji</option>
            <option value="finland">Finland</option>
            <option value="france">France</option>
            <option value="gabon">Gabon</option>
            <option value="gambia">Gambia</option>
            <option value="georgia">Georgia</option>
            <option value="germany">Germany</option>
            <option value="ghana">Ghana</option>
            <option value="greece">Greece</option>
            <option value="grenada">Grenada</option>
            <option value="guatemala">Guatemala</option>
            <option value="guinea">Guinea</option>
            <option value="guinea-bissau">Guinea-Bissau</option>
            <option value="guyana">Guyana</option>
            <option value="haiti">Haiti</option>
            <option value="honduras">Honduras</option>
            <option value="hungary">Hungary</option>
            <option value="iceland">Iceland</option>
            <option value="india">India</option>
            <option value="indonesia">Indonesia</option>
            <option value="iran">Iran</option>
            <option value="iraq">Iraq</option>
            <option value="ireland">Ireland</option>
            <option value="israel">Israel</option>
            <option value="italy">Italy</option>
            <option value="jamaica">Jamaica</option>
            <option value="japan">Japan</option>
            <option value="jordan">Jordan</option>
            <option value="kazakhstan">Kazakhstan</option>
            <option value="kenya">Kenya</option>
            <option value="kiribati">Kiribati</option>
            <option value="kuwait">Kuwait</option>
            <option value="kyrgyzstan">Kyrgyzstan</option>
            <option value="laos">Laos</option>
            <option value="latvia">Latvia</option>
            <option value="lebanon">Lebanon</option>
            <option value="lesotho">Lesotho</option>
            <option value="liberia">Liberia</option>
            <option value="libya">Libya</option>
            <option value="liechtenstein">Liechtenstein</option>
            <option value="lithuania">Lithuania</option>
            <option value="luxembourg">Luxembourg</option>
            <option value="madagascar">Madagascar</option>
            <option value="malawi">Malawi</option>
            <option value="malaysia">Malaysia</option>
            <option value="maldives">Maldives</option>
            <option value="mali">Mali</option>
            <option value="malta">Malta</option>
            <option value="marshall-islands">Marshall Islands</option>
            <option value="mauritania">Mauritania</option>
            <option value="mauritius">Mauritius</option>
            <option value="mexico">Mexico</option>
            <option value="micronesia">Micronesia</option>
            <option value="moldova">Moldova</option>
            <option value="monaco">Monaco</option>
            <option value="mongolia">Mongolia</option>
            <option value="montenegro">Montenegro</option>
            <option value="morocco">Morocco</option>
            <option value="mozambique">Mozambique</option>
            <option value="myanmar">Myanmar</option>
            <option value="namibia">Namibia</option>
            <option value="nauru">Nauru</option>
            <option value="nepal">Nepal</option>
            <option value="netherlands">Netherlands</option>
            <option value="new-zealand">New Zealand</option>
            <option value="nicaragua">Nicaragua</option>
            <option value="niger">Niger</option>
            <option value="nigeria">Nigeria</option>
            <option value="north-korea">North Korea</option>
            <option value="north-macedonia">North Macedonia</option>
            <option value="norway">Norway</option>
            <option value="oman">Oman</option>
            <option value="pakistan">Pakistan</option>
            <option value="palau">Palau</option>
            <option value="palestine">Palestine</option>
            <option value="panama">Panama</option>
            <option value="papua-new-guinea">Papua New Guinea</option>
            <option value="paraguay">Paraguay</option>
            <option value="peru">Peru</option>
            <option value="philippines">Philippines</option>
            <option value="poland">Poland</option>
            <option value="portugal">Portugal</option>
            <option value="qatar">Qatar</option>
            <option value="romania">Romania</option>
            <option value="russia">Russia</option>
            <option value="rwanda">Rwanda</option>
            <option value="saint-kitts-and-nevis">Saint Kitts and Nevis</option>
            <option value="saint-lucia">Saint Lucia</option>
            <option value="saint-vincent-and-the-grenadines">Saint Vincent and the Grenadines</option>
            <option value="samoa">Samoa</option>
            <option value="san-marino">San Marino</option>
            <option value="sao-tome-and-principe">Sao Tome and Principe</option>
            <option value="saudi-arabia">Saudi Arabia</option>
            <option value="senegal">Senegal</option>
            <option value="serbia">Serbia</option>
            <option value="seychelles">Seychelles</option>
            <option value="sierra-leone">Sierra Leone</option>
            <option value="singapore">Singapore</option>
            <option value="slovakia">Slovakia</option>
            <option value="slovenia">Slovenia</option>
            <option value="solomon-islands">Solomon Islands</option>
            <option value="somalia">Somalia</option>
            <option value="south-africa">South Africa</option>
            <option value="south-korea">South Korea</option>
            <option value="south-sudan">South Sudan</option>
            <option value="spain">Spain</option>
            <option value="sri-lanka">Sri Lanka</option>
            <option value="sudan">Sudan</option>
            <option value="suriname">Suriname</option>
            <option value="sweden">Sweden</option>
            <option value="switzerland">Switzerland</option>
            <option value="syria">Syria</option>
            <option value="taiwan">Taiwan</option>
            <option value="tajikistan">Tajikistan</option>
            <option value="tanzania">Tanzania</option>
            <option value="thailand">Thailand</option>
            <option value="timor-leste">Timor-Leste</option>
            <option value="togo">Togo</option>
            <option value="tonga">Tonga</option>
            <option value="trinidad-and-tobago">Trinidad and Tobago</option>
            <option value="tunisia">Tunisia</option>
            <option value="turkey">Turkey</option>
            <option value="turkmenistan">Turkmenistan</option>
            <option value="tuvalu">Tuvalu</option>
            <option value="uganda">Uganda</option>
            <option value="ukraine">Ukraine</option>
            <option value="united-arab-emirates">United Arab Emirates</option>
            <option value="united-kingdom">United Kingdom</option>
            <option value="united-states">United States</option>
            <option value="uruguay">Uruguay</option>
            <option value="uzbekistan">Uzbekistan</option>
            <option value="vanuatu">Vanuatu</option>
            <option value="venezuela">Venezuela</option>
            <option value="vietnam">Vietnam</option>
            <option value="yemen">Yemen</option>
            <option value="zambia">Zambia</option>
            <option value="zimbabwe">Zimbabwe</option>
          </select>

        </div>

        <div class="mb-3">
          <label for="state" class="form-label">State/Province:</label>
          <input type="text" id="state" class="form-control" required v-model="formData.state" placeholder="State or province">
        </div>

        <div class="mb-3">
          <label for="city" class="form-label">City:</label>
          <input type="text" id="city" class="form-control" required v-model="formData.city" placeholder="City">
        </div>

        <button class="btn bg-gradient-primary mt-4 w-100" type="submit">
          Create Token
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SoftAlert from "@/components/SoftAlert.vue";

export default {
  name: "CreateTokenForm",
  components: { SoftAlert },
  data() {
    return {
      formData: {
        name: '',
        business_area: '',
        company_size: '',
        owner: '',
        country: '',
        state: '',
        city: ''
      },
      messages: {}
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await axios.post('https://core-bastion.codeloop.co/api/organizations', this.formData, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`
          }
        });
        this.messages = {
          success: true,
          text: response.data.message
        };
      } catch (error) {
//        console.error('Error creating token:', error);
        this.messages = {
          success: false,
          text: error.data.message
        };
      }
    }
  }
};
</script>
