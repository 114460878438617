<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <hybrid-transaction-token />
      </div>
    </div>
  </div>
</template>

<script>
// import HybridToken from "./components/HybridTokensTable.vue";

import HybridTransactionToken from "./components/HybridTransactionsTable.vue";

export default {
  name: "Hybrid Transactions",
  components: {
    HybridTransactionToken
  },
};
</script>
