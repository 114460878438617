<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h2 class="mb-4">Register Human Profile</h2>
      <SoftAlert
        v-if="messages.success !== undefined || editable"
        :icon="messages.success || editable ? 'fas fa-thumbs-up' : 'fas fa-times-circle'"
        :message="messages.text || 'Registered Token: ' + token"
        :color="messages.success || editable ? 'success' : 'danger'"
      />
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="full_name" class="form-label required">Full Name:</label>
          <input type="text" id="full_name" class="form-control" required v-model="formData.full_name" placeholder="Enter full name">
        </div>

        <div class="mb-3">
          <label for="dob" class="form-label required">Date of Birth:</label>
          <input type="date" id="dob" class="form-control" required v-model="formData.dob">
        </div>

        <div class="mb-3">
          <label for="email" class="form-label required">Email:</label>
          <input type="email" id="email" class="form-control" required v-model="formData.email" placeholder="Enter email">
        </div>

        <div class="mb-3">
          <label for="phone_number" class="form-label required">Phone Number:</label>
          <input type="tel" id="phone_number" class="form-control" required v-model="formData.phone_number" placeholder="Enter phone number">
        </div>

        <div class="mb-3">
          <label for="document_number" class="form-label required">Document Number:</label>
          <input type="text" id="document_number" class="form-control" required v-model="formData.document_number" placeholder="Enter document number">
        </div>

        <div class="mb-3">
          <label for="address" class="form-label required">Address:</label>
          <input type="text" id="address" class="form-control" required v-model="formData.address" placeholder="Enter address">
        </div>

        <button class="btn bg-gradient-primary mt-4 w-100" type="submit">
          {{ editable ? 'Update Human Profile' : 'Create Human Profile' }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SoftAlert from "@/components/SoftAlert.vue";

export default {
  name: "RegisterHumanProfile",
  components: { SoftAlert },
  data() {
    return {
      editable: false,
      token: '',
      formData: {
        id: '',
        full_name: '',
        dob: '',
        email: '',
        phone_number: '',
        document_number: '',
        address: ''
      },
      messages: {}
    };
  },
  methods: {
    async fetchTokens() {
      try {
        const response = await axios.get('https://core-bastion.codeloop.co/api/human-profiles', {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`
          }
        });
        this.formData.full_name = response.data.human.full_name;
        this.formData.dob = response.data.human.dob;
        this.formData.email = response.data.human.email;
        this.formData.phone_number = response.data.human.phone_number;
        this.formData.document_number = response.data.human.document_number;
        this.formData.address = response.data.human.address;

        if (this.formData.full_name !== ''){
          this.editable = true;
          this.formData.id = response.data.human.id;
          this.token = response.data.human.token;
        }
        // this.data = response.data.data;
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    },
    async submitForm() {
      let response;  // Definindo response fora do escopo condicional
      try {
        if (this.editable) {
          response = await axios.put(`https://core-bastion.codeloop.co/api/human-profiles/${this.formData.id}`, this.formData, {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`
            }
          });
        } else {
          response = await axios.post('https://core-bastion.codeloop.co/api/human-profiles', this.formData, {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`
            }
          });
        }
        this.messages = {
          success: true,
          text: "Profile registered successfully. Token: " + response.data.profile.token
        };
      } catch (error) {
        this.messages = {
          success: false,
          text: "Failed to register profile. Error: " + (error.response && error.response.data.message ? error.response.data.message : error.message)
        };
      }
    }
  },
  mounted() {
    this.fetchTokens();
  }
};
</script>