<template>
    <div class="container top-0 position-sticky z-index-sticky">
        <main class="mt-0 main-content main-content-bg" style="text-align:center;">
            <Loader v-if="loading" />
            <span v-if="loading" class="fade-in">Connecting...</span>

        </main>
    </div>
</template>


<script>

export default {
    name: "SignIn",
    components: {
    },
    data() {
        return {
            email: '',
            loading: true,
            emailGet: this.$route.query.email || null,
            uuid: this.$route.query.uuid || null,
        };
    },
    created() {
        this.login();
    },
    methods: {
        login() {
            this.$store.dispatch('login', {
                email: this.emailGet,
                uuid: this.uuid
            }).then(response => {
                if (response.status === 200) {  // Verifique se o status da resposta é 200 (OK)
                    this.$router.push('/dashboard');
                } else {
                    alert('Failed to login');
                }
            }).catch(error => {
                alert('Failed to login');
                console.log(error);
            }).finally(() => {
                this.loading = false;
            });
        },
    }
};
</script>

<style>
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 3s ease-in-out infinite;
}
</style>
