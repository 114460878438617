<template>

  <div class="card mb-4">
    <div class="card-header pb-0">
      <h2 class="mb-4">Asset Form</h2>
      <SoftAlert
        v-if="messages && messages.success === true"
        icon="fas fa-thumbs-up" :message="this.messages.text" />
      <SoftAlert
        v-if="messages && messages.success === false"
        icon="fas fa-thumbs-up" :message="this.messages.text" color="danger" />
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="assetType" class="form-label">Asset Type:</label>
          <select id="assetType" v-model="selectedAsset" class="form-select">
            <option value="">Select an asset type</option>
            <option value="coffee">Coffee</option>
            <option value="oil">Oil</option>
            <option value="gold">Gold</option>
          </select>
        </div>

        <div v-if="selectedAsset === 'coffee'" class="mt-4">
          <CoffeeForm @update-asset-data="updateAssetData" />
        </div>

        <div v-if="selectedAsset === 'oil'" class="mt-4">
          <OilForm @update-asset-data="updateAssetData" />
        </div>

        <div v-if="selectedAsset === 'gold'" class="mt-4">
          <GoldForm @update-asset-data="updateAssetData" />
        </div>
        <button
          class="btn bg-gradient-warning mt-4 w-50"
          type="submit"
        ><i class="fas fa-plus"> </i> Create your token</button
        >
        <button
          class="btn bg-gradient-faded- mt-4 w-50"
          type="button"
        ><i class="fas fa-plus"> </i> Back</button
        >
      </form>
    </div>
  </div>
</template>

<script>
import CoffeeForm from "./components/CoffeeForm.vue";
import OilForm from "./components/OilForm.vue";
import GoldForm from "./components/GoldForm.vue";
import axios from "axios";
import SoftAlert from "../components/SoftAlert.vue";

export default {
  name: "HybridTokensForm",
  components: { SoftAlert, GoldForm, OilForm, CoffeeForm },
  methods: {
    updateAssetData(data) {
      this.assetData = data;
    },
    async submitForm() {
      const combinedData = {
        'type_token': this.selectedAsset,
        'user_id': 1,
        ...this.formData,
        ...this.assetData,
      };
      console.log(combinedData);
      try {
        const response = await axios.post('https://core-bastion.codeloop.co/api/create-token', combinedData);
        console.log(response.status)
        if (response.status === 200){
          this.messages.text = response.data.message
          this.messages.success = true
        }else {
          this.messages.text = response.data.message
          this.messages.success = false
        }
        // Trate a resposta do servidor conforme necessário
      } catch (error) {
        this.messages.success = false
        this.messages = 'Error processing token, please try again or contact support'
        // Trate o erro conforme necessário
      }
    },
  },
  data() {
    return {
      selectedAsset: "",
      formData: {},
      assetData: {},
      messages: {

      }
    };
  },
};
</script>