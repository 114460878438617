<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse navText="Dashboard" :to="{ name: 'Dashboard' }">
          <template #icon>
            <Dashboard />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Hybrid Assets" :to="{ name: 'Hybrid Assets' }">
          <template #icon>
            <Assets />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Sell" :to="{ name: 'Hybrid Sellers' }">
          <template #icon>
            <Assets />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Transactions" :to="{ name: 'Hybrid Transactions' }">
          <template #icon>
            <Assets />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Wallet" :to="{ name: 'Billing' }">
          <template #icon>
            <Wallet />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="One True Organization" :to="{ name: 'One True Organization' }">
          <template #icon>
            <Assets />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="One True Human" :to="{ name: 'One True Human' }">
          <template #icon>
            <Assets />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="One True Data" :to="{ name: 'One True Data' }">
          <template #icon>
            <Assets />
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          BASTION INSTITUTIONS
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Bastion Bank" :to="{ name: 'Profile' }">
          <template #icon>
            <Bank />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Bastion Stocks" :to="{ name: 'Sign In' }">
          <template #icon>
            <Stocks />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Bastion Smart Chain" :to="{ name: 'Sign In' }">
          <template #icon>
            <SmartChain />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Bastion Exchange" :to="{ name: 'Sign In' }">
          <template #icon>
            <Exchange />
          </template>
        </sidenav-collapse>
      </li> -->
    </ul>
  </div>
  <!-- <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :class="cardBg"
      textPrimary="Need Help?"
      textSecondary="Please check our docs"
      route="https://codeloop.co/"
      label="Documentation"
      icon="ni ni-diamond"
    />
    <a
      class="btn bg-gradient-warning mt-4 w-100"
      href="http://hml.codeloop.com.br"
      type="button"
      >Back to Ecosystem 2.0</a
    >
  </div> -->
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCard from "./SidenavCard.vue";
// import Shop from "../../components/Icon/Shop.vue";
// import Office from "../../components/Icon/Office.vue";
// import CreditCard from "../../components/Icon/CreditCard.vue";
// import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
// import Document from "../../components/Icon/Document.vue";
import Dashboard from "../../components/Icon/Dashboard.vue";
import Assets from "../../components/Icon/Assets.vue";
import Wallet from "../../components/Icon/Wallet.vue";
// import Bank from "../../components/Icon/Bank.vue";
// import Stocks from "../../components/Icon/Stocks.vue";
// import SmartChain from "../../components/Icon/SmartChain.vue";
// import Exchange from "../../components/Icon/Exchange.vue";
//import Settings from "@/components/Icon/Settings.vue";
//import Office from "@/components/Icon/Office.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
//      Settings,
//    Office,
    // Exchange,
    // SmartChain,
    // Stocks,
    // Bank,
    Wallet,
    Assets,
    Dashboard,
    SidenavCollapse,
    // SidenavCard,
    // Shop,
    // Office,
    // CreditCard,
    // CustomerSupport,
//     Document,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
