<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h2 class="mb-4">Transaction Token</h2>
      <SoftAlert
        v-if="messages && messages.success === true"
        icon="fas fa-thumbs-up" :message="messages.text" />
      <SoftAlert
        v-if="messages && messages.success === false"
        icon="fas fa-thumbs-up" :message="messages.text" color="danger" />
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="receiverUser" class="form-label required">Receiver User:</label>
          <select id="receiverUser" v-model="formData.receiver_id" required class="form-select">
            <option selected disabled>Select a receiver user</option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }} - {{ user.email }}
            </option>
          </select>
        </div>

        <div class="mb-3">
          <label for="token" class="form-label required">Select Token:</label>
          <select id="token" v-model="formData.token_id" required class="form-select">
            <option selected disabled>Select your Token</option>
            <option v-for="token in tokens" :key="token.id" :value="token.id">
              {{ token.token_id }}
            </option>
          </select>
        </div>

        <div class="mb-3">
          <label for="quantity" class="form-label required">Quantity:</label>
          <input type="number" id="quantity" class="form-control" required v-model="formData.quantity" placeholder="Enter the quantity tokens">
        </div>

        <button
          class="btn bg-gradient-warning mt-4 w-50"
          type="submit"
        ><i class="fas fa-plus"> </i> Transaction token</button>
        <button
          class="btn bg-gradient-faded- mt-4 w-50"
          type="button"
        ><i class="fas fa-plus"> </i> Back</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SoftAlert from "../components/SoftAlert.vue";

export default {
  name: "HybridTokensForm",
  components: { SoftAlert },
  data() {
    return {
      formData: {
        receiver_id: '',
        token_id: '',
        quantity: ''
      },
      users: [],
      tokens: [],
      messages: {}
    };
  },
  async mounted() {
    try {
      const usersResponse = await axios.get("https://core-bastion.codeloop.co/api/get-users");
      this.users = usersResponse.data;

      const tokensResponse = await axios.get("https://core-bastion.codeloop.co/api/get-tokens");
      this.tokens = tokensResponse.data;
    } catch (error) {
      console.error("Error fetching users or tokens:", error);
    }
  },
  methods: {
    async submitForm() {
      try {
        const response = await axios.post('https://core-bastion.codeloop.co/api/create-transaction', this.formData);
        console.log(response.status)
        if (response.status === 200){
          this.messages.text = response.data.message
          this.messages.success = true
        }else {
          this.messages.text = response.data.message
          this.messages.success = false
        }
        // Trate a resposta do servidor conforme necessário
      } catch (error) {
        this.messages.success = false
        this.messages = 'Error processing token, please try again or contact support'
        // Trate o erro conforme necessário
      }
    },
  },
};
</script>
