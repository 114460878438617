<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <WalletInvoicesTable />
      </div>
    </div>
  </div>
</template>

<script>
// import HybridToken from "./components/HybridTokensTable.vue";

import WalletInvoicesTable from "./components/WalletInvoicesTable.vue";

export default {
  name: "Wallet Invoices",
  components: {
    WalletInvoicesTable
  },
};
</script>
