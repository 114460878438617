<!-- CoffeeForm.vue -->
<template>
  <div>

    <div class="mb-3 row">
      <div class="col-6">
      <label for="quantity" class="form-label required">Quantity:</label>
      <input type="number" id="quantity" class="form-control" required v-model="coffeeData.quantity" placeholder="Enter the quantity (e.g., 5 or 25,5)">
      </div>

      <div class="col-6">
      <label for="quantity_type" class="form-label required">Quantity Type:</label>
      <input type="text" id="origin" class="form-control" required v-model="coffeeData.quantity_type" placeholder="Enter the quantity type (e.g., kg or bags)">
      </div>
    </div>

    <div class="mb-3">
      <label for="origin" class="form-label required">Origin:</label>
      <input type="text" id="origin" class="form-control" required v-model="coffeeData.origin" placeholder="Enter the country or region of origin">
    </div>

    <div class="mb-3">
      <label for="coffeeType" class="form-label required">Coffee Type:</label>
      <select id="coffeeType" v-model="coffeeData.type_asset" required class="form-select">
        <option selected disabled>Select a coffee type</option>
        <option value="arabica">Arábica</option>
        <option value="robusta">Robusta</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="process" class="form-label required">Process:</label>
      <select id="process" v-model="coffeeData.coffee_process" required class="form-select">
        <option selected disabled>Select a processing method</option>
        <option value="washed">Washed</option>
        <option value="natural">Natural</option>
        <option value="honey">Honey</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="classification" class="form-label required">Classification:</label>
      <select id="classification" v-model="coffeeData.classification" required class="form-select">
        <option selected disabled>Select a quality classification</option>
        <option value="specialty">Specialty</option>
        <option value="gourmet">Gourmet</option>
        <option value="commercial">Commercial</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="tastingNotes" class="form-label">Tasting Notes:</label>
      <textarea id="tastingNotes" class="form-control" v-model="coffeeData.coffee_notes" rows="3" placeholder="Enter the tasting notes"></textarea>
    </div>

    <div class="mb-3">
      <label for="harvestDate" class="form-label required">Harvest Date:</label>
      <input type="date" id="harvestDate" required v-model="coffeeData.coffee_date" class="form-control">
    </div>

    <div class="mb-3">
      <label for="storage" class="form-label required">Storage:</label>
      <input type="text" id="storage" class="form-control" required v-model="coffeeData.storage" placeholder="Enter storage location and conditions">
    </div>

    <div class="mb-3">
      <label for="producer" class="form-label required">Producer:</label>
      <input type="text" id="producer" class="form-control" required v-model="coffeeData.producer" placeholder="Enter the producer's name and contact information">
    </div>

    <div class="mb-3">
      <label for="certifications" class="form-label">Certifications:</label>
      <input type="text" id="certifications" class="form-control" v-model="coffeeData.certifications" placeholder="Enter relevant certifications (e.g., organic, Fair Trade)">
    </div>

    <div class="mb-3 row">
      <div class="col-6">
        <label for="image1" class="form-label">Image 1:</label>
        <input type="file" id="image1" class="form-control" @change="onFileChange('img_1', $event)">
      </div>

      <div class="col-6">
        <label for="image2" class="form-label">Image 2:</label>
        <input type="file" id="image2" class="form-control" @change="onFileChange('img_2', $event)">
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "CoffeeForm",
  data() {
    return {
      coffeeData: {
        // coffee fields
      },
      image1: null,
      image2: null,
    };
  },
  methods: {
    emitUpdate() {
      this.$emit("update-asset-data", this.coffeeData);
    },
    onFileChange(image, event) {
      const files = event.target.files;
      if (files.length > 0) {
        this[image] = files[0];
      } else {
        this[image] = null;
      }
    },
  },
  watch: {
    coffeeData: {
      deep: true,
      handler() {
        this.emitUpdate();
      },
    },
  },
};
</script>

<style scoped>

</style>