<template>
  <div>
    <div class="mb-3 row">
      <div class="col-6">
      <label for="quantity" class="form-label required">Quantity:</label>
      <input type="text" id="quantity" v-model="oilData.quantity" required class="form-control" placeholder="Enter the quantity (e.g., 100, 200)">
    </div>

      <div class="col-6">
      <label for="quantity" class="form-label required">Quantity Type:</label>
      <input type="text" id="quantity" v-model="oilData.quantity_type" required class="form-control" placeholder="Enter the quantity type (e.g., barrels)">
      </div>
    </div>

    <div class="mb-3">
      <label for="oilType" class="form-label required">Oil Type:</label>
      <select id="oilType" v-model="oilData.type_asset" required class="form-select">
        <option selected disabled>Select an oil type</option>
        <option value="brent">Brent Crude</option>
        <option value="wti">West Texas Intermediate (WTI)</option>
        <option value="dubai">Dubai Crude</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="origin" class="form-label required">Origin:</label>
      <input type="text" id="origin" class="form-control" required v-model="oilData.origin" placeholder="Enter the country or region of origin">
    </div>

    <div class="mb-3">
      <label for="extractionDate" class="form-label required">Extraction Date:</label>
      <input type="date" id="extractionDate" required v-model="oilData.extract_date" class="form-control">
    </div>

    <div class="mb-3">
      <label for="quality" class="form-label">Quality:</label>
      <input type="text" id="quality" class="form-control" v-model="oilData.oil_quality" placeholder="Enter quality information (e.g., API gravity, sulfur content)">
    </div>

    <div class="mb-3">
      <label for="producer" class="form-label required">Producer:</label>
      <input type="text" id="producer" class="form-control" required v-model="oilData.producer" placeholder="Enter the producer's name and contact information">
    </div>

    <div class="mb-3">
      <label for="storage" class="form-label required">Storage:</label>
      <input type="text" id="storage" class="form-control" required v-model="oilData.storage" placeholder="Enter storage location and conditions">
    </div>

    <div class="mb-3">
      <label for="certifications" class="form-label">Certifications:</label>
      <input type="text" id="certifications" class="form-control" v-model="oilData.certifications" placeholder="Enter relevant certifications or standards (e.g., environmental, safety)">
    </div>

    <div class="mb-3">
      <label for="futuresContract" class="form-label">Futures Contract:</label>
      <input type="text" id="futuresContract" v-model="oilData.futures_contract" class="form-control" placeholder="Enter futures contract information (e.g., exchange, expiration date)">
    </div>

    <div class="mb-3 row">
      <div class="col-6">
        <label for="image1" class="form-label">Image 1:</label>
        <input type="file" id="image1" class="form-control" @change="onFileChange('img_1', $event)">
      </div>

      <div class="col-6">
        <label for="image2" class="form-label">Image 2:</label>
        <input type="file" id="image2" class="form-control" @change="onFileChange('img_2', $event)">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OilForm",
  data() {
    return {
      oilData: {
        // coffee fields
      },
      image1: null,
      image2: null,
    };
  },
  methods: {
    emitUpdate() {
      this.$emit("update-asset-data", this.oilData);
    },
    onFileChange(image, event) {
      const files = event.target.files;
      if (files.length > 0) {
        this[image] = files[0];
      } else {
        this[image] = null;
      }
    },
  },
  watch: {
    oilData: {
      deep: true,
      handler() {
        this.emitUpdate();
      },
    },
  },
};
</script>

<style scoped>

</style>