<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <hybrid-sells />
      </div>
    </div>
  </div>
</template>

<script>
import HybridSells from "./components/HybridSellersTable.vue";

export default {
  name: "Hybrid Sellers",
  components: {
    HybridSells,
  },
};
</script>
