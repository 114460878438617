import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: false,
    showSidenav: !!localStorage.getItem('token'),
    showNavbar: true,
    showFooter: true,
    showMain: true,
    navbarFixed: "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    isAuthenticated: !!localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')) || null,
    token: localStorage.getItem('token') || '',
  },
  mutations: {
    SET_AUTHENTICATION(state, payload) {
      state.isAuthenticated = true;
      state.showSidenav = true;
      state.user = payload.user;
      state.token = payload.token;
      localStorage.setItem('user', JSON.stringify(payload.user));
      localStorage.setItem('token', payload.token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`;
    },
    CLEAR_AUTHENTICATION(state) {
      state.isAuthenticated = false;
      state.showSidenav = false;
      state.user = null;
      state.token = '';
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
//      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
    login({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios.post( `${process.env.VUE_APP_API_URl}/api/auth/login`, credentials)
          .then(response => {
            const data = response.data;
            localStorage.setItem('token', data.token); // Salva o token no localStorage
            commit('SET_AUTHENTICATION', { user: data.user, token: data.token });
            resolve(response);
          })
          .catch(error => {
            commit('CLEAR_AUTHENTICATION');
            reject(error);
          });
      });
    },
    logout({ commit }) {
      commit('CLEAR_AUTHENTICATION'); // Limpa o usuário e token
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    currentUser: state => state.user,
    },
});
