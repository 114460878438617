<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <a
        class="btn bg-gradient-warning mt-4 w-20"
        href="/form-hybrid-transactions"
        type="button"
      ><i class="fas fa-plus"> </i> Transaction Token</a
      >
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Asset
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Receiver / Sender
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Date
              </th>

              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Transaction hash
              </th>
              <th class="text-secondary opacity-7">

              </th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(token, index) in tokens" :key="index">
            <td>
              <div class="d-flex px-2 py-1">
                <div>
                  <soft-avatar
                    :img="img1"
                    size="sm"
                    border-radius="lg"
                    class="me-3"
                    alt="user1"
                  />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ token.token_data.token_id }}</h6>
                  <p class="text-xs text-secondary mb-0">
                    {{ token.token_data.quantity }} / {{ token.token_data.quantity_type}}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{ token.receiver.username }} - {{ token.receiver.email }}</p>
              <p class="text-xs text-secondary mb-0">{{ token.sender.username }} - {{ token.sender.email }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <soft-badge color="success" variant="gradient" size="sm"
              >FINISHED</soft-badge
              >
            </td>
            <td class="align-middle text-center">
      <span class="text-secondary text-xs font-weight-bold"
      >{{ token.transaction_date }}</span
      >
            </td>

            <td class="align-middle text-center">
      <span class="text-secondary text-xs font-weight-bold"
      >{{ token.transaction_hash }}</span
      >
            </td>
            <td class="align-middle">
              <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="View token">
                <i class="fas fa-eye"></i>
              </a>
            </td>
          </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import img1 from "../../assets/img/team-2.jpg";
import img2 from "../../assets/img/team-3.jpg";
import img3 from "../../assets/img/team-4.jpg";
import img4 from "../../assets/img/team-3.jpg";
import img5 from "../../assets/img/team-2.jpg";
import img6 from "../../assets/img/team-4.jpg";
import axios from 'axios';


export default {
  name: "hybrid-transaction-token",
  created() {
    this.fetchTokens();
  },
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      tokens: [],
    };
  },
  methods: {
    async fetchTokens() {
      try {
        const response = await axios.get('https://core-bastion.codeloop.co/api/get-transactions', {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`
          }
        });
        this.tokens = response.data;
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    },
  },
  components: {
    SoftAvatar,
    SoftBadge,
  },
};
</script>
