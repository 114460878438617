<template>
  <div class="form-group" :class="hasIcon(icon)">
    <div class="input-group">
      <span v-if="iconDir === 'left'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
      <input
        :id="id"
        :type="type"
        class="form-control"
        :class="getClasses(size, success, error)"
        :name="name"
        :placeholder="placeholder"
        :value="value"
        @input="handleInput"
        :required="isRequired"
        />
      <span v-if="iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SoftInput",
  props: {
    size: String,
    success: Boolean,
    error: Boolean,
    icon: String,
    iconDir: String,
    name: String,
    id: String,
    value: String,
    placeholder: String,
    type: { type: String, default: 'text' },
    isRequired: Boolean,
  },
  methods: {
    getClasses(size, success, error) {
      let classes = [];
      if (size) classes.push(`form-control-${size}`);
      if (error) classes.push('is-invalid');
      else if (success) classes.push('is-valid');
      return classes.join(' ');
    },
    getIcon(icon) {
      return icon || '';
    },
    hasIcon(icon) {
      return icon ? 'input-group' : '';
    },
    handleInput(event) {
     return this.$emit('input', event.target.value);
    }
  },
};
</script>