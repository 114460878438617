<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h2 class="mb-4">Wallet Invoice {{formData.invoice_code?'View':'Create'}}</h2>
        </div>
        <div class="col-6 text-end">
          <router-link :to="{ path: '/wallet/invoices', params: {} }" class="btn bg-gradient-warning w-20" >Back</router-link>
          <button :to="{ path: '/wallet/invoices', params: {} }" class="btn bg-gradient-warning w-10 mx-2" @click="editable=!editable" v-if="[1,7].includes($store.getters.currentUser.role)"><i class="fas fa-pen"/></button>
        </div>
      </div>

    </div>

     <div class="card-body ">

      <h6 class="mb-3" v-if="formData.invoice_code">#{{formData.invoice_code}}</h6>

      <form @submit.prevent="submitForm">

        <!-- CUSTOMER DATA -->
        <!-- <div class="text-bold mt-5">Customer Data</div> -->
        
        <div class="mb-3">
            <label for="customer" class="form-label required">Customer</label>
            <select id="customer" v-model="formData.user_id" class="form-select" :disabled="formData.invoice_code || loading" required>
              <option disabled selected v-if="usersList.length==0">Empty</option> 
              <option disabled selected v-else value="">Select</option> 
              <option v-for="(s,i) in usersList" :key="i" :value="s.value" >{{ s.text }}</option>
            </select>
          </div>

        <!-- <div class="mb-3">
          <label for="customer_name" class="form-label required">Customer Name:</label>
          <input type="text" id="customer_name" class="form-control" required v-model="formData.customer_name" placeholder="" :disabled="!editable || loading">
        </div>

        <div class="mb-3">
          <label for="customer_email" class="form-label required">Customer Email:</label>
          <input type="email" id="customer_email" class="form-control" required v-model="formData.customer_email" placeholder="" :disabled="!editable || loading">
        </div>

        <div class="mb-3">
          <label for="customer_address" class="form-label ">Customer Address:</label>
          <input type="text" id="customer_address" class="form-control" v-model="formData.customer_address" placeholder="" :disabled="!editable || loading">
        </div> -->

        
        <!-- <div class="text-bold">Invoice Data</div> -->
        <div class="mb-3">
          <label for="emission_date" class="form-label required">Emission Date:</label>
          <input type="date" id="emission_date" class="form-control" required v-model="formData.emission_date" placeholder="Ex: 2024-01-01" :disabled="formData.invoice_code || loading">
        </div>

        <div class="mb-3">
          <label for="due_date" class="form-label required">Due Date</label>
          <input type="date" id="due_date" class="form-control" required v-model="formData.due_date" :disabled="formData.invoice_code || loading">
        </div>

        <div class="mb-3">
          <label for="description" class="form-label required">Description:</label>
          <textarea type="description" id="description" class="form-control" required v-model="formData.description" placeholder="Description" :disabled="formData.invoice_code || loading"></textarea>
        </div>

        <div class="mb-3">
          <label for="currencies" class="form-label ">Currencies</label>
          <select id="currencies" v-model="formData.currencies" class="form-select" :disabled="formData.invoice_code|| loading">
            <option selected value="USD">USD</option> 
            <option selected value="EUR">EUR</option>
            <option selected value="BRL">BRL</option>
          </select>
        </div>

        

        <div class="mb-3">
          <label for="price" class="form-label required">Price:</label>
          <input type="text" id="price" class="form-control" required v-model="formData.price" placeholder="Ex: 0.00" :disabled="formData.invoice_code || loading" @keyup="formData.total=(formData.price)">
        </div>

        <!-- <div class="mb-3">
          <label for="quantity" class="form-label required">Quantity:</label>
          <input type="number" min="1" max="100" id="quantity" class="form-control" required v-model="formData.quantity" @keyup="formData.total=(formData.price*formData.quantity)" @change="formData.total=(formData.price*formData.quantity)" placeholder="0-100" :disabled="!editable || loading">
        </div> -->

        <div class="mb-3">
          <label for="total" class="form-label required">Total:</label>
          <input type="text" id="total" class="form-control" required v-model="formData.total" disabled placeholder="Ex: 0.00">
          <div class="small mx-2">Amount: {{formData.total ? parseInt(formData.total) : 0}} LPC</div>
        </div>
        

        

        <div class="mb-3" v-if="formData.invoice_code">
          <label for="status" class="form-label required">Status:</label>
          <select id="status" v-model="formData.status" required class="form-select" :disabled="!editable || loading">
            <option v-for="(s,i) in statusList" :key="i" :value="s.value" :disabled="!formData.invoice_code">{{ s.text }}</option>
          </select>
        </div>

        <div class="mb-3" v-if="formData.invoice_code">
          <label for="wallet_transaction" class="form-label required">Transaction:</label>
          <select id="wallet_transaction" v-model="formData.wallet_transaction" required class="form-select" :disabled="!editable || loading">
            <option selected value="Pending">Pending</option> 
            <option selected value="Processing">Processing</option>
            <option selected value="Completed">Completed</option>
          </select>
        </div>


        


        <div v-if="formData.invoice_code">

          <!-- <div class="text-bold mt-5">Payment Details</div> -->
          <!-- <div class="mb-3">
            <label for="payment_method" class="form-label ">Payment Method:</label>
            <select id="payment_method" v-model="formData.payment_method"  class="form-select" :disabled="!editable || loading">
              <option selected value="PayPal">PayPal</option>
              <option selected value="Credit Card">Credit Card</option>
            </select>
          </div> -->

          <!-- <div class="mb-3">
            <label for="payment_status" class="form-label ">Payment Status:</label>
            <select id="payment_status" v-model="formData.payment_status"  class="form-select" :disabled="!editable || loading">
              <option selected value="Pending">Pending</option>
              <option selected value="Processing">Processing</option>
              <option selected value="Paid" :disabled="!formData.invoice_code">Paid</option>
              <option selected value="Payment Failed" :disabled="!formData.invoice_code">Payment Failed</option>
            </select>
          </div> -->

          <div class="mb-3">
            <label for="payment_id" class="form-label ">Payment ID:</label>
            <input type="text" id="payment_id" class="form-control"  v-model="formData.payment_id" placeholder="" :disabled="!editable || loading">
          </div>

          <!-- <div class="mb-3">
            <label for="payment_total" class="form-label ">Payment Total:</label>
            <input type="text" id="payment_total" class="form-control"  v-model="formData.payment_total" placeholder="Ex: 0.00" :disabled="!editable || loading">
          </div> -->

          <!-- <div class="mb-3">
            <label for="fees_applied" class="form-label ">Fees Applied:</label>
            <input type="text" id="fees_applied" class="form-control"  v-model="formData.fees_applied" placeholder="Ex: 0.00" :disabled="!editable || loading">
          </div> -->

          <div class="mb-3">
            <label for="payment_date" class="form-label ">Payment Date:</label>
            <input type="date" id="payment_date" class="form-control"  v-model="formData.payment_date" placeholder="Ex: 0.00" :disabled="!editable || loading">
          </div>

          <div class="mb-3">
            <label for="payment_link_paypal" class="form-label ">Payment link:</label>
            <input type="text" id="payment_link_paypal" class="form-control"  v-model="formData.payment_link_paypal" placeholder="" :disabled="!editable || loading" v-if="editable">
            
            <div class="mx-2">
              <i class="fas fa-link mr-1 text-xs"/> <a target="_blank" :href="formData.payment_link_paypal" class="text-secondary text-xs text-dark font-weight-bold" v-if="formData.payment_link_paypal">{{formData.payment_link_paypal}}</a>
            </div>
            
          </div>

          <div class="text-bold mt-5">Other Information</div>
          
          <div class="mb-3">
            <label for="additional_notes" class="form-label">Additional Notes:</label>
            <textarea type="description" id="additional_notes" class="form-control" v-model="formData.additional_notes" placeholder="" :disabled="!editable || loading"></textarea >
          </div>

        </div>


        <!-- <SoftAlert
          v-if="messages.success !== undefined || editable"
          :icon="messages.success || editable ? 'fas fa-thumbs-up' : 'fas fa-times-circle'"
          :message="messages.text || 'Registered Token: ' + token"
          :color="messages.success || editable ? 'success' : 'danger'"
        /> -->

        <button class="btn bg-gradient-warning mt-4 w-100" type="submit" v-if="editable || !formData.invoice_code" :disabled="loading">
          {{ loading ? 'Wait...' : (editable && formData.invoice_code ? 'Update Invoice' : 'Create Invoice') }}
        </button>
      </form>

     </div>
  </div>
</template>

<script>
import axios from "axios";
// import SoftAlert from "@/components/SoftAlert.vue";

export default {
  name: "RegisterHumanProfile",
  components: { 
    // SoftAlert 
  },
  mounted() {

    // alert(process.env.NODE_ENV);
    // alert(process.env.VUE_APP_TITLE);
    // alert(process.env.VUE_APP_API_URl);

    if ( this.$route.params.invoice_code) {
      this.handleShowInvoice();
    } else {
      this.editable = true;
      
    }
  },
  watch: {
    editable() {
      if ( this.editable ) {
        this.fetchUsers();
      }
    }
  },
  data() {
    return {
      loading: false,
      usersList: [],
      editable: false,
      token: '',
      statusList: [
        { value: 'Pending', text: 'Pending'},
        { value: 'Paid', text: 'Paid'},
        { value: 'Canceled', text: 'Canceled'}
      ],
      formData: {
        user_id: '',
        // Dados da Fatura (Invoice)
        invoice_code: '',
        emission_date: '',

        due_date: '',
        description: '',
        quantity: '1',
        price: '',
        total: '',
        status: 'Pending',

        // Dados do Cliente
        // customer_name: '',
        // customer_email: '',
        // customer_address: '',

        // Detalhes de Pagamento
        payment_method: '',
        payment_status: '',
        payment_id: '',
        payment_total: '',
        fees_applied: '',
        payment_date: '',
        payment_link_paypal: '',

        // Outras Informações
        currencies: 'USD',
        additional_notes: '',
      },
      messages: {}
    };
  },
  methods: {
    // if ( this.$route.params.invoice_code) {
      
    // }

    fetchUsers() {
      let self = this;
      axios.get(`${process.env.VUE_APP_API_URl}/api/wallet/invoices/users`).then(response => {

        if (response.data.status == 'success') {
          self.usersList = response.data.data
        } else {
          alert(response.data.message);
        }          
      }).catch(error => {
        if (error.response.data.message) {
          alert(error.response.data.message);
        } else {
          console.log(error);
        }        
      });
    },

    handleShowInvoice() {
      let self = this;
      axios.get(`${process.env.VUE_APP_API_URl}/api/wallet/invoices/${self.$route.params.invoice_code}/show`).then(response => {
        if (response.data.status == 'success') {
          self.formData = response.data.data;
        } else {
          alert(response.data.message);
        }          
      }).catch(error => {
        if (error.response.data.message) {
          alert(error.response.data.message);
        } else {
          console.log(error);
        }        
      });
    },

    async submitForm() {
      let self = this;

      self.loading = true;
      
      let method = self.formData.invoice_code ? 
        axios.put(`${process.env.VUE_APP_API_URl}/api/wallet/invoices/${self.formData.invoice_code}/update`, self.formData) : 
        axios.post(`${process.env.VUE_APP_API_URl}/api/wallet/invoices/store`, self.formData)

      await method.then(response => {
        console.log('Wallet Invoices', response.data)
        if (response.data.status == 'success') {
          // alert(response.data.message);
          self.$router.replace({ path: '/wallet/invoices', query: {} })
        } else {
          alert(response.data.message);
        }          
      })
      .catch(error => {
        if (error.response.data.message) {
          alert(error.response.data.message);
        } else {
          console.log(error);
        }        
      })
      .finally(() => self.loading = false);
    },





    // async submitForm() {
    //   let response;  // Definindo response fora do escopo condicional
    //   try {
    //     if (this.editable) {
    //       response = await axios.put(`https://core-bastion.codeloop.co/api/human-profiles/${this.formData.id}`, this.formData, {
    //         headers: {
    //           Authorization: `Bearer ${this.$store.state.token}`
    //         }
    //       });
    //     } else {
    //       response = await axios.post('https://core-bastion.codeloop.co/api/human-profiles', this.formData, {
    //         headers: {
    //           Authorization: `Bearer ${this.$store.state.token}`
    //         }
    //       });
    //     }
    //     this.messages = {
    //       success: true,
    //       text: "Profile registered successfully. Token: " + response.data.profile.token
    //     };
    //   } catch (error) {
    //     this.messages = {
    //       success: false,
    //       text: "Failed to register profile. Error: " + (error.response && error.response.data.message ? error.response.data.message : error.message)
    //     };
    //   }
    // }
  },

};
</script>