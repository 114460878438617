<template>
    <div class="card mb-4">
        <div class="card-header pb-0">
            <a
                class="btn bg-gradient-warning mt-4 w-20"
                href="/form-oto"
                type="button"
            ><i class="fas fa-plus"> </i> Registry your Token</a>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                    <thead>
                    <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ORG Name</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Country
                            Origin
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Owner
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Token
                        </th>
                        <th class="text-secondary opacity-7"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(org, index) in orgs" :key="index">
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div>{{ org.name }}</div>
                            </div>
                        </td>
                        <td class="align-middle text-center text-sm">
                            <soft-badge color="success" variant="gradient" size="sm">{{ org.country }}</soft-badge>
                        </td>
                        <td>
                            <p class="text-xs text-secondary mb-0">{{ org.owner }}</p>
                        </td>
                        <td>
                            <p class="text-xs text-secondary mb-0">{{ org.tokens[0].token }}</p>
                        </td>
                        <td class="align-middle text-center">
                            <a href="javascript:;" @click="viewToken(org.tokens[0].token)"
                               class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" title="View token">
                                <i class="fas fa-eye"></i>
                            </a>
                            <a href="javascript:;" @click="confirmDeletion(org.id)"
                               class="text-xs" data-toggle="tooltip" title="Delete token">
                                <i class="fas fa-trash"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal" v-if="showModal" tabindex="-1" role="dialog" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="tokenModalLabel">Token Details</h5>
                    <button type="button" class="close" @click="showModal = false">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{ selectedToken }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="showModal = false">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-dialog {
    background: white;
    border-radius: 5px;
    padding: 20px;
}
</style>


<script>
import SoftBadge from "@/components/SoftBadge.vue";
import axios from 'axios';

export default {
    name: "OTOList",
    components: {
        SoftBadge,
    },
    data() {
        return {
            orgs: [],
            selectedToken: null,
            showModal: false
        };
    },
    mounted() {
        this.fetchTokens();
    },
    methods: {
        async fetchTokens() {
            try {
                const response = await axios.get('https://core-bastion.codeloop.co/api/organizations', {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.token}`
                    }
                });
                this.orgs = response.data;
            } catch (error) {
                console.error('Error fetching tokens:', error);
            }
        },
        viewToken(token) {
            this.selectedToken = token;
            this.showModal = true; // Mostra o modal definindo showModal como true
        },
        async confirmDeletion(orgId) {
            if (confirm('Are you sure you want to delete this token?')) {
                await this.deleteToken(orgId);
            }
        },
        async deleteToken(orgId) {
            try {
                await axios.delete(`https://core-bastion.codeloop.co/api/organizations/${orgId}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.token}`
                    }
                });
                this.orgs = this.orgs.filter(org => org.id !== orgId);
                alert('Token deleted successfully.');
            } catch (error) {
                console.error('Error deleting token:', error);
                alert('Failed to delete token.');
            }
        }
    }
};
</script>
